export const projects = [
  /*  {
                title: "Neural Network Visualization",
                stateName: "irisNeuralNet",
                imageId: "irisNeuralNetIMG",
                description: "Iris dataset with visualizations using D3, and computations using Numeric.js."
            },

            {
                title: "Image to threeJS model using ES",
                stateName: "imageToThreeModel",
                imageId: "imageToThreeModelIMG",
                description: "ES example that creates a Three.js model from an image."
            },
        */
  {
    title: 'Detectable World',
    description: 'Detect objects on a map using TensorflowJS.',
    url: 'https://www.detectable.world/',
    imageSrc: '/img/detectableworld.jpg',
  },
  {
    title: 'Comparison of Polygon Reduction Algorithms',
    description:
      'Comparison of performance and computer geometry of three popular polygon reduction algorithms.',
    url: 'https://dulldrums.github.io/polygon-reduction-algorithms-demo/',
    imageSrc: '/img/simplifyline.jpg',
  },
  {
    title: 'Leaflet - K Means Cluster Analysis',
    description:
      'Map point cluster analysis using K Means. "Gift-Wrapping" algorithm implemented in JS to draw boundaries of clusters.',
    url: 'https://dulldrums.github.io/leaflet-k-means/',
    imageSrc: '/img/clusters.jpg',
  },
  {
    title: 'Leaflet - Evolutionary Algorithm Example',
    description:
      'Evolutionary algorithm example to estimate approximate geometry. Written in JS, uses Leaflet and Turf.js',
    url: 'https://codepen.io/dulldrums/full/LzKdoY/',
    imageSrc: '/img/leafletEvolve.jpg',
  },
];

import React from 'react';
import ProjectsListItem from './ProjectsListItem';
import { projects } from '../Utils/projectsUtils';

const ProjectsList = (props) => {
  return (
    <div>
      {projects.map((project) => (
        <ProjectsListItem key={project.title} project={project} />
      ))}
    </div>
  );
};

export default ProjectsList;

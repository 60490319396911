import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCodepen,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import styles from './IconRow.module.css';

const IconRow = () => {
  return (
    <div>
      <a
        href="https://github.com/dulldrums"
        className={`${styles['unlink']} ${styles['icon-right-padding']}`}
        aria-label="github profile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} size="5x" />
      </a>
      <a
        href="https://codepen.io/dulldrums/"
        className={`${styles['unlink']} ${styles['icon-right-padding']}`}
        aria-label="codepen profile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faCodepen} size="5x" />
      </a>
      <a
        href="https://twitter.com/DullDrums"
        className={styles['unlink']}
        aria-label="twitter profile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} size="5x" />
      </a>
    </div>
  );
};

export default IconRow;

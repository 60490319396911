import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProjectsListItem.module.css';

const ProjectsListItem = (props) => {
  const { project } = props;
  const { url, imageSrc, title, description } = project;

  return (
    <div className={styles['project-list-item']}>
      <a
        className={styles.unlink}
        href={url}
        aria-label={`${title}`}
        rel="noopener"
      >
        <div className={`${styles.projectRow} ${styles.unlink}`}>
          <div className={styles.cell}>
            <div className={styles.imageclass}>
              <img src={imageSrc} alt={`Project {title}`} />
            </div>
          </div>

          <div className={styles.cell}>
            <div className={styles.textTitle}>
              <div className={styles.unlink}>{title}</div>
            </div>
            <div className={`${styles.projectDesc} ${styles.nocursor}`}>
              {description}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

ProjectsListItem.propTypes = {
  project: PropTypes.shape({
    url: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectsListItem;

import React from 'react';
import ProjectsList from './ProjectsList';
import Footer from './Footer';
import MainContent from './MainContent';
import styles from './MainApp.module.css';

const MainApp = (props) => {
  console.log(styles);
  return (
    <div className={styles['main']}>
      <MainContent />

      <div className={styles['article-content']}>
        <div className={styles['projects-title']}>Projects</div>

        {/* <HR /> */}

        <ProjectsList />
      </div>

      <Footer />
    </div>
  );
};

export default MainApp;

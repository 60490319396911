import React from 'react';
import IconRow from './IconRow';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles['footer']}>
      <div className={styles['first-footer-row']}>
        <IconRow />
      </div>
      <div className={styles['second-footer-row']}>
        <span>Feel free to contact me at ryansims1729@gmail.com</span>
      </div>
    </div>
  );
};

export default Footer;

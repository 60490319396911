import React from 'react';
import styles from './MainContent.module.css';
import IconRow from './IconRow';

const MainContent = (props) => {
  return (
    <div>
      <div className={styles['main-container']}>
        <div className={styles['svg-container']}>
          <div className={styles['svg-content']}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
              <defs>
                <mask
                  id="MASK"
                  maskUnits="userSpaceOnUse"
                  maskContentUnits="userSpaceOnUse"
                >
                  <image
                    href="/img/sphere.gif" // sphere
                    height="100%"
                    width="100%"
                  />
                </mask>
              </defs>
              <g mask="url(#MASK)">
                <image
                  x="0"
                  y="0%"
                  class="space"
                  href="/img/gradient.gif"
                  height="100%"
                  width="100%"
                />
              </g>
            </svg>
          </div>
        </div>

        <div className={styles['title-container']}>
          <h1 className={styles['title']}>dulldrums.io</h1>
          <h2 className={styles['sub-title']}>Maps, Music, Geometry</h2>
        </div>

        <div className={styles['social']}>
          <IconRow />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
